import React, { Component, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';

//Import Images
import LogoWhite from '../../../assets/images/extension/logo-white.png';
import logoIcon from '../../../assets/images/extension/logo-purple-icon.png';
import svgMap from '../../../assets/images/svg-map.svg';

//Import Switcher

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};

class Footer4 extends Component {
  render() {
    return (
      <React.Fragment>
        <Suspense fallback={Loader()}>
          <footer
            className="footer"
            style={{
              backgroundImage: `url(${svgMap})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
          >
            <Container>
              <Row className="justify-content-center">
                <Col className="col-12 py-lg-5">
                  <div className="footer-py-60 text-center">
                    <Link to="#" className="logo-footer">
                      <img src={LogoWhite} height="32" alt="" />
                    </Link>
                    <p className="mt-4 para-desc mx-auto">
                      Upgrade your language skills today with HighVocab – the
                      ultimate language learning companion for your daily
                      browsing.
                    </p>
                    <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                      <li className="list-inline-item">
                        <a
                          href="https://www.facebook.com/profile.php?id=100090749021226"
                          className="rounded"
                          target={'_blank'}
                          rel="noopener noreferrer"
                        >
                          <FeatherIcon
                            icon="facebook"
                            className="fea icon-sm fea-social"
                          />
                        </a>
                      </li>{' '}
                      <li className="list-inline-item">
                        <Link to="#" className="rounded">
                          <FeatherIcon
                            icon="instagram"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>{' '}
                      <li className="list-inline-item">
                        <a
                          href="https://twitter.com/HighVocab_Com"
                          className="rounded"
                          target={'_blank'}
                          rel="noopener noreferrer"
                        >
                          <FeatherIcon
                            icon="twitter"
                            className="fea icon-sm fea-social"
                          />
                        </a>
                      </li>{' '}
                      <li className="list-inline-item">
                        <Link to="#" className="rounded">
                          <FeatherIcon
                            icon="linkedin"
                            className="fea icon-sm fea-social"
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>

            <div className="footer-py-30 footer-bar bg-footer">
              <Container className="text-center">
                <Row className="align-items-center justify-content-between">
                  <Col lg={3} md={2} sm={3}>
                    <div className="text-sm-start">
                      <Link to="#" className="logo-footer">
                        <img src={logoIcon} height="34" alt="" />
                      </Link>
                    </div>
                  </Col>

                  <Col
                    lg={6}
                    md={6}
                    sm={6}
                    className="mt-4 mt-sm-0 pt-2 pt-sm-0"
                  >
                    <ul className="list-unstyled footer-list terms-service mb-0">
                      <li className="list-inline-item mb-0">
                        <a href="/privacy" className="text-foot me-2">
                          Privacy
                        </a>
                      </li>{' '}
                      <li className="list-inline-item mb-0">
                        <a href="/terms" className="text-foot me-2">
                          Terms
                        </a>
                      </li>{' '}
                      <li className="list-inline-item mb-0">
                        <a href="/#highvocab-faq" className="text-foot me-2">
                          FAQs
                        </a>
                      </li>{' '}
                      <li className="list-inline-item mb-0">
                        <a href="https://tally.so/r/wv0jxA" className="text-foot" target="_blank" rel="noreferrer">
                          Contact
                        </a>
                      </li>{' '}
                    </ul>
                  </Col>

                  <Col
                    lg={3}
                    md={4}
                    sm={3}
                    className="mt-4 mt-sm-0 pt-2 pt-sm-0"
                  >
                    <div className="text-sm-end">
                      <p className="mb-0 text-foot">
                        © {new Date().getFullYear()}{' '}
                        <Link
                          to="/"
                          rel="noreferrer"
                          target="_blank"
                          className="text-reset"
                        >
                          HighVocab.
                        </Link>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </footer>

          {/* theme switcher */}
        </Suspense>
      </React.Fragment>
    );
  }
}

export default Footer4;
