import React from 'react';
import { useQuery } from 'react-query';
import { Col, Row, Table } from 'reactstrap';
import { CheckoutService } from '../../../services';

const BillingHistory = () => {
  const { data = {} } = useQuery('getInvoices', CheckoutService.getInvoices, {
    retry: false,
  });

  const billingHistory = data.data || [];

  const totalAmountInCents = billingHistory.reduce(
    (sum, item) => sum + item.amount,
    0,
  );
  const totalAmountInDollars = totalAmountInCents / 100;

  return billingHistory ? (
    <>
      <div className="d-flex align-items-center mt-4">
        <h5 className="mb-0 text-primary">Billing History:</h5>
      </div>
      <div className="invoice-table pb-4 mt-4">
        <div className="table-responsive bg-white shadow rounded">
          <Table className="mb-0 table-center invoice-tb">
            <thead className="bg-light">
              <tr>
                <th scope="col" className="border-bottom text-start">
                  Invoice Number
                </th>
                <th scope="col" className="border-bottom text-start">
                  Invoice Date
                </th>
                <th scope="col" className="border-bottom">
                  Status
                </th>
                <th scope="col" className="border-bottom">
                  Amount Due
                </th>
              </tr>
            </thead>
            <tbody>
              {billingHistory.map((item, key) => (
                <tr key={key}>
                  <th scope="row" className="text-start">
                    {key + 1}
                  </th>
                  <td className="text-start">{item.invoice_date}</td>
                  <td>{item.paid === "1" ? 'Paid' : 'Not paid'}</td>
                  <td>{item.amount_in_dollar}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <Row>
          <Col lg="4" md="5" className="ms-auto">
            <ul className="list-unstyled h6 fw-normal mt-4 mb-0 ms-md-5 ms-lg-4">
              <li className="d-flex justify-content-between">
                Total :<span>${totalAmountInDollars}</span>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </>
  ) : null;
};

export default BillingHistory;
