import FeatherIcon from 'feather-icons-react';
import React, { Suspense, useEffect, useState } from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import imgbg from '../../assets/images/account/bg.png';
import profile from '../../assets/images/client/06.jpg';
import LogoPurple from '../../assets/images/extension/logo-purple.png';
import logoLight from '../../assets/images/extension/logo-white.png';
import { FREE_VERSION } from '../../config';
import { useUserContext } from '../../context/userProvider';
import Footer4 from '../../pages/Pages/PageFooterLayouts/Footer4';

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};

const UserLayout = ({ children }) => {
  const location = useLocation();
  const { currentUser, logout } = useUserContext();
  const [isOpen, setIsOpen] = useState();

  useEffect(() => {
    const handleScroll = () => {
      var doc = document.documentElement;
      var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
      if (top > 80) {
        document.getElementById('topnav').classList.add('nav-sticky');
        document.querySelector('.settingbtn').classList.remove('btn-light');
        document.querySelector('.settingbtn').classList.add('btn-soft-primary');
      } else {
        document.getElementById('topnav').classList.remove('nav-sticky');
        document
          .querySelector('.settingbtn')
          .classList.remove('btn-soft-primary');
        document.querySelector('.settingbtn').classList.add('btn-light');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <React.Fragment>
      <Suspense fallback={Loader()}>
        <header id="topnav" className="defaultscroll sticky">
          <Container>
            <div>
              <Link className="logo" to="/">
                <img
                  src={LogoPurple}
                  height="24"
                  className="logo-dark-mode"
                  alt=""
                />
                <img
                  src={logoLight}
                  height="24"
                  className="logo-light-mode"
                  alt=""
                />
              </Link>
            </div>
            <div className="menu-extras">
              <div className="menu-item">
                <Link
                  to="#"
                  onClick={() => setIsOpen((prevState) => !prevState)}
                  className={isOpen ? 'navbar-toggle open' : 'navbar-toggle'}
                >
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </Link>
              </div>
            </div>
            <ul className="buy-button list-inline mb-0">
              <li className="list-inline-item mb-0">
                <Link to="#" onClick={() => logout()}>
                  <div className="btn btn-icon btn-pills btn-light settingbtn">
                    <FeatherIcon icon="log-out" className="fea icon-sm" />
                  </div>
                </Link>
              </li>{' '}
            </ul>
            <div id="navigation" style={{ display: isOpen ? 'block' : 'none' }}>
              <ul className="navigation-menu nav-dark nav-light">
                <li
                  className={
                    location.pathname === '/vocabularies' ? 'active' : ''
                  }
                >
                  <a className="sub-menu-item" href="/vocabularies">
                    Vocabularies
                  </a>
                </li>
                <li
                  className={location.pathname === '/phrases' ? 'active' : ''}
                >
                  <a className="sub-menu-item" href="/phrases">
                    Phrases
                  </a>
                </li>
                {!FREE_VERSION && <li
                  className={location.pathname === '/payment' ? 'active' : ''}
                >
                  <a className="sub-menu-item" href="/payment">
                    Payment
                  </a>
                </li>}
                <li
                  className={location.pathname === '/settings' ? 'active' : ''}
                >
                  <a className="sub-menu-item" href="/settings">
                    Settings
                  </a>
                </li>
              </ul>
            </div>
          </Container>
        </header>
        <section
          className="bg-profile d-table w-100 bg-primary"
          style={{ background: `url(${imgbg}) center center`, height: 260 }}
        >
          <Container>
            <Row>
              <Col lg="12"></Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container>
            <Card
              className="public-profile border-0 rounded shadow"
              style={{ zIndex: '1', marginTop: -100 }}
            >
              <CardBody>
                <Row className="align-items-center">
                  <Col lg="2" md="3" className="text-md-start text-center">
                    <img
                      src={profile}
                      className="avatar avatar-large rounded-circle shadow d-block mx-auto"
                      alt=""
                    />
                  </Col>

                  <Col lg="10" md="9">
                    <Row className="align-items-center">
                      <Col
                        md="7"
                        className="text-md-start text-center mt-4 mt-sm-0"
                      >
                        <h3 className="title mb-0">
                          {currentUser.email.split('@')[0]}
                        </h3>
                        <small className="text-muted h6 me-2">
                          {currentUser.subscribed
                            ? 'Premium Member'
                            : 'Free Member'}
                        </small>
                      </Col>
                      <Col md="5" className="text-md-end text-center">
                        <div className="sidebar sticky-bar p-4">
                          <div className="widget">
                            <div className="row mt-4">
                              <div className="col-6 text-center">
                                <a href="/vocabularies">
                                  <FeatherIcon
                                    icon="edit"
                                    className="fea icon-ex-md text-primary mb-1"
                                  />
                                  <h5
                                    className="mb-0"
                                    style={{ color: 'black' }}
                                  >
                                    {currentUser.vocabulary_count}
                                  </h5>
                                  <p className="text-muted mb-0">
                                    Vocabularies
                                  </p>
                                </a>
                              </div>

                              <div className="col-6 text-center">
                                <a href="/phrases">
                                  <FeatherIcon
                                    icon="book-open"
                                    className="fea icon-ex-md text-primary mb-1"
                                  />
                                  <h5
                                    className="mb-0"
                                    style={{ color: 'black' }}
                                  >
                                    {currentUser.phrase_count}
                                  </h5>
                                  <p className="text-muted mb-0">Phrases</p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </section>
        {children}
        <Footer4 />
      </Suspense>
    </React.Fragment>
  );
};
export default withRouter(UserLayout);
