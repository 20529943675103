import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  Alert,
  Button,
  Col,
  Container,
  FormGroup,
  Row,
  Table,
} from 'reactstrap';
import { VocabularyService } from '../../../services';
import useDebounce from '../../../utils/useDebounce';
import fileDownload from 'js-file-download';
import Pagination from './Pagination';
import moment from 'moment';

const SIZE = 10;

const PageVocabularies = () => {
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const synth = window.speechSynthesis;

  const speak = (text) => {
    const uttr = new SpeechSynthesisUtterance();
    uttr.text = text;
    uttr.rate = 0.8;
    synth.speak(uttr);
  };

  const debouncedKeyword = useDebounce(keyword?.trim(), 500);
  const variables = {
    keyword: debouncedKeyword,
    page: Number(page),
    size: SIZE,
  };

  const { data, isLoading, refetch } = useQuery(
    ['getVocabularies', variables],
    () => VocabularyService.getVocabularies(variables),
    {
      retry: false,
    },
  );

  const items = data?.data || [];
  const count = data?.vocabularyCount || 0;
  const totalPage = Math.ceil(count / SIZE);

  const { mutate } = useMutation(VocabularyService.exportVocabularies, {
    onSuccess: (data) => {
      const date = moment().format('YYYY_MM_DD');
      const filename = `Vocabularies_${date}.csv`;
      fileDownload(data, filename);
    },
  });

  return (
    <section className="section p-0">
      <Container>
        <Row>
          <Col xs={8}>
            <div className="text-center subcribe-form mb-2 mt-2">
              <form className="m-0" style={{ maxWidth: '300px' }}>
                <FormGroup className="mb-0 mt-3">
                  <input
                    type="text"
                    id="name"
                    className="rounded-pill shadow"
                    placeholder="Search Vocabulary..."
                    onChange={(e) => {
                      setKeyword(e.target.value);
                    }}
                  />
                </FormGroup>
              </form>
            </div>
          </Col>
          <Col xs={4}>
            <div className="mb-2 mt-2" style={{ textAlign: 'right' }}>
              <Button
                color="primary"
                type="submit"
                className="mb-0 mt-3 mb-3 btn-sm"
                onClick={mutate}
              >
                <i className="uil uil-file-download"></i> CSV EXPORT
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="table-responsive bg-white shadow rounded mb-4 p-3">
              <Table className="mb-0 table-center">
                <thead className="bg-light">
                  <tr>
                    <th
                      scope="col"
                      className="border-bottom"
                      style={{ minWidth: '300px' }}
                    >
                      Vocabularies
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading && items.length === 0 ? (
                    <div className="loader">Loading ...</div>
                  ) : items.length > 0 ? (
                    items.map((d, i) => (
                      <tr key={d.id}>
                        <td>
                          <div className="d-flex">
                            <div className="flex-1 content ms-3">
                              <a
                                className="forum-title text-primary fw-bold"
                                style={{ fontWeight: 'bold' }}
                              >
                                {String(d.vocabulary).charAt(0).toUpperCase() +
                                  String(d.vocabulary).slice(1)}
                              </a>
                              {d.pronunciation && (
                                <>
                                  {' '}
                                  -{' '}
                                  <i
                                    style={{ cursor: 'pointer' }}
                                    className="uil uil-volume-up text-muted h5"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      speak(d.vocabulary);
                                    }}
                                  />{' '}
                                  <span className="text-muted fw-normal">
                                    {d.pronunciation}
                                  </span>
                                </>
                              )}
                              <a className="forum-title text-primary">
                                {' '}
                                - {d.translation}
                              </a>
                              <p className="small mb-0 mt-2">
                                {d.definition && d.definition}
                              </p>
                              <p className="text-muted small mb-0 mt-2">
                                Example: {d.example}
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <Row className="mt-3">
                      <Col lg={5} md={8}>
                        <Alert color="info">
                          You haven't saved any vocabularies yet. Dive into the
                          world of words and start building your own collection.
                        </Alert>
                      </Col>
                    </Row>
                  )}
                </tbody>
              </Table>
              {totalPage >= 1 && (
                <Pagination
                  currentPage={page}
                  cb={setPage}
                  totalPage={totalPage}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PageVocabularies;
