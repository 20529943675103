import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

const PagePrivacy = ({}) => {
  return (
    <React.Fragment>
      <section className="section">
        <section className="d-table w-100" style={{ paddingBottom: 60 }}>
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h4 className="title mb-0"> Our Privacy Policy </h4>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <Container>
          <Row className="justify-content-center">
            <Col lg={9}>
              <Card className="shadow border-0 rounded">
                <CardBody>
                  <h5 className="card-title">Last updated: May 2, 2023</h5>
                  <p className="text-muted">
                    This Privacy Policy governs the privacy of users ("you") who
                    interact with the HighVocab Browser (Edge, Chrome, Firefox) Extension ("we", "us",
                    "our", or "HighVocab") and explains how we collect, use, and
                    protect your information.
                  </p>
                  <p className="text-muted">
                    By using the HighVocab Browser (Edge, Chrome, Firefox) Extension, you agree to the
                    collection and use of information in accordance with this
                    policy. If you do not agree with the terms of this policy,
                    please do not use the HighVocab Browser (Edge, Chrome, Firefox) Extension.
                  </p>
                  <h5 className="card-title">
                    1. Information Collection and Use
                  </h5>
                  <p className="text-muted">
                    To provide you with the best experience, we require you to{' '}
                    <b className="text-danger">log in</b> using your{' '}
                    <b className="text-danger">email address</b> and{' '}
                    <b className="text-danger">password</b>. We collect this
                    information only to{' '}
                    <b className="text-danger">authenticate your identity</b>{' '}
                    and grant access to the HighVocab Browser (Edge, Chrome, Firefox) Extension features.
                    We do not use your email address or any other personal
                    information for any other purposes.
                  </p>
                  <p className="text-muted">
                    We do <b className="text-danger">not collect or track</b>{' '}
                    your <b className="text-danger">browsing history</b> or any
                    other actions you take while using the the extension. Our
                    focus is on providing you with an{' '}
                    <b className="text-danger">
                      enhanced vocabulary learning experience
                    </b>
                    , and we have no interest in accessing or monitoring your
                    online activities.
                  </p>
                  <h5 className="card-title">
                    2. Information Storage and Security
                  </h5>
                  <p className="text-muted">
                    We are committed to ensuring the{' '}
                    <b className="text-danger">security of your information</b>.
                    We implement appropriate measures, including{' '}
                    <b className="text-danger">encryption</b>, to protect your
                    email address, password, and any other information you
                    provide from{' '}
                    <b className="text-danger">
                      unauthorized access, alteration, disclosure, or
                      destruction
                    </b>
                    .
                  </p>
                  <h5 className="card-title">
                    3. Changes to This Privacy Policy
                  </h5>
                  <p className="text-muted">
                    We reserve the right to{' '}
                    <b className="text-danger">update or modify</b> this Privacy
                    Policy at any time. Any changes to this policy will be
                    posted on this page, and we will update the "Last updated"
                    date at the top of this Privacy Policy. Your continued use
                    of HighVocab after any changes to this Privacy Policy will
                    constitute your{' '}
                    <b className="text-danger">acceptance of the changes</b>.
                  </p>
                  <h5 className="card-title">4. Contact Us</h5>
                  <p className="text-muted">
                    If you have any questions or concerns about this Privacy
                    Policy or our handling of your information, please feel free
                    to contact us at info@highvocab.com
                  </p>
                  Thank you for using HighVocab! We appreciate your trust in us
                  and are committed to protecting your privacy.
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default PagePrivacy;
