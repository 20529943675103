import axios from 'axios';
import { TOKEN_KEY, BACKEND_URL } from '../config';

const api = axios.create({
  baseURL: BACKEND_URL,
});

api.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = window.localStorage.getItem(TOKEN_KEY);
      if (token) {
        const { accessToken } = JSON.parse(token);
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default api;
