import FeatherIcon from 'feather-icons-react';
import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

const PageTerms = ({}) => {
  return (
    <React.Fragment>
      <section className="section">
        <section className="d-table w-100" style={{ paddingBottom: 60 }}>
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h4 className="title mb-0"> Terms of Services </h4>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <Container>
          <Row className="justify-content-center">
            <Col lg={9}>
              <Card className="shadow border-0 rounded">
                <CardBody>
                  <h5 className="card-title">Last updated: May 2, 2023</h5>
                  <p className="text-muted">
                    Please read these Terms of Service ("Terms") carefully
                    before using the HighVocab Browser (Edge, Chrome, Firefox) Extension ("HighVocab",
                    "we", "us", "our", or "the Extension"). Your access to and
                    use of the Extension is conditioned on your acceptance of
                    and compliance with these Terms. These Terms apply to all
                    users who access or use the HighVocab Browser (Edge, Chrome, Firefox) Extension.
                  </p>
                  <p className="text-muted">
                    By using the HighVocab Browser (Edge, Chrome, Firefox) Extension, you agree to be
                    bound by these Terms. If you do not agree with the Terms,
                    please do not use the HighVocab Browser (Edge, Chrome, Firefox) Extension.
                  </p>
                  <h5 className="card-title">Intellectual Property:</h5>
                  <p className="text-muted">
                    All content, including but not limited to, text, images,
                    graphics, logos, and software, in the HighVocab Chrome
                    Extension is the property of HighVocab or its content
                    suppliers and is protected by copyright, trademark, and
                    other intellectual property laws. You may not reproduce,
                    modify, distribute, or otherwise use any content from the
                    HighVocab Browser (Edge, Chrome, Firefox) Extension without our express written
                    permission.
                  </p>
                  <h5 className="card-title">Restrictions:</h5>
                  <p className="text-muted">
                    You agree not to use the HighVocab Browser (Edge, Chrome, Firefox) Extension to:
                    <ul>
                      <li>
                        Engage in any illegal, fraudulent, or unauthorized
                        activities;
                      </li>
                      <li>
                        Interfere with or disrupt the operation of the HighVocab
                        Chrome Extension or the servers or networks connected to
                        it;
                      </li>
                      <li>
                        Attempt to gain unauthorized access to any features or
                        systems of the HighVocab Browser (Edge, Chrome, Firefox) Extension;
                      </li>
                      <li>
                        Use the HighVocab Browser (Edge, Chrome, Firefox) Extension for any purpose other
                        than its intended use as a vocabulary learning tool.
                      </li>
                    </ul>
                  </p>
                  <h5 className="card-title">Termination:</h5>
                  <p className="text-muted">
                    We reserve the right to terminate your access to the
                    HighVocab Browser (Edge, Chrome, Firefox) Extension at any time, without notice, for
                    any reason, including but not limited to, a breach of these
                    Terms.
                  </p>
                  <h5 className="card-title">Changes to These Terms:</h5>
                  <p className="text-muted">
                    We may update or modify these Terms at any time. Any changes
                    to these Terms will be posted on this page, and we will
                    update the "Last updated" date at the top of these Terms.
                    Your continued use of HighVocab after any changes to these
                    Terms will constitute your acceptance of the changes.
                  </p>
                  <h5 className="card-title">
                    Disclaimer of Warranties and Limitation of Liability:
                  </h5>
                  <p className="text-muted">
                    The HighVocab Browser (Edge, Chrome, Firefox) Extension is provided on an "as is" and
                    "as available" basis, without warranties of any kind, either
                    express or implied, including but not limited to, warranties
                    of merchantability, fitness for a particular purpose, and
                    non-infringement. We do not warrant that the HighVocab
                    Chrome Extension will be error-free or uninterrupted, or
                    that any defects will be corrected.
                  </p>
                  <p className="text-muted">
                    In no event shall HighVocab or its affiliates, directors,
                    employees, or agents be liable for any direct, indirect,
                    incidental, special, consequential, or punitive damages,
                    including but not limited to, loss of profits, data, use, or
                    goodwill, arising out of or in connection with the use or
                    inability to use the HighVocab Browser (Edge, Chrome, Firefox) Extension.
                  </p>
                  <h5 className="card-title">Indemnification:</h5>
                  <p className="text-muted">
                    You agree to defend, indemnify, and hold harmless HighVocab,
                    its affiliates, and their respective directors, employees,
                    and agents from and against any and all claims, damages,
                    losses, liabilities, and expenses, including but not limited
                    to, reasonable attorney's fees, arising out of or in
                    connection with your use of the HighVocab Browser (Edge, Chrome, Firefox) Extension
                    or your breach of these Terms.
                  </p>

                  <h5 className="card-title">
                    Governing Law and Jurisdiction:
                  </h5>
                  <p className="text-muted">
                    These Terms shall be governed by and construed in accordance
                    with the laws of the State of California, without regard to
                    its conflicts of law provisions.
                  </p>
                  <p className="text-muted">
                    Any dispute arising out of or related to these Terms or the
                    HighVocab Browser (Edge, Chrome, Firefox) Extension shall be submitted to
                    confidential arbitration in the State of California. The
                    arbitration shall be conducted in accordance with the rules
                    of the American Arbitration Association. The arbitrator's
                    award shall be binding and may be entered as a judgment in
                    any court of competent jurisdiction.
                  </p>
                  <h5 className="card-title">Entire Agreement:</h5>
                  <p className="text-muted">
                    These Terms constitute the entire agreement between you and
                    HighVocab regarding the use of the HighVocab
                    Extension, superseding any prior agreements between you and
                    us.
                  </p>
                  <h5 className="card-title">Contact Us:</h5>
                  <p className="text-muted">
                    If you have any questions or concerns about these Terms,
                    please contact us at info@highvocab.com
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default PageTerms;
