import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Card, CardBody, Col, Container, Label, Row } from 'reactstrap';
import { useUserContext } from '../../../context/userProvider';
import { SourceService, UserService } from '../../../services';

const PageSettings = () => {
  const { currentUser, setCurrentUser } = useUserContext();

  const [backgroundColor, setBackgroundColor] = useState(
    currentUser?.background_color ? currentUser.background_color : null,
  );
  const [textColor, setTextColor] = useState(
    currentUser?.text_color ? currentUser.text_color : '#9381ff',
  );
  const [underline, setUnderline] = useState(
    currentUser?.underline ? currentUser.underline : false,
  );
  const [language, setLanguage] = useState(currentUser?.primary_language_id);

  const { data } = useQuery('sourceData', SourceService.getSourceData, {
    retry: false,
  });

  const profileUpdate = useMutation(UserService.profileUpdate, {
    onSuccess: (data) => {
      if (data) setCurrentUser(data);
    },
    onError: ({ response }) => {
      // console.log(
      //   response.data && response.data.description
      //     ? response.data.description
      //     : response.statusText,
      // );
    },
  });

  const onChange = (e) => {
    setLanguage(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    profileUpdate.mutate({
      primary_language_id: language,
      background_color: backgroundColor,
      text_color: textColor,
      underline,
    });
  };

  return (
    <section className="section p-0 mt-3">
      <Container>
        <Col>
          <Card className="border-0 rounded shadow">
            <CardBody>
              <form onSubmit={onSubmit}>
                <h5 className="text-md-start text-center">Personal Detail :</h5>
                <Row className="mt-4">
                  <Col lg="6">
                    <div className="mb-3">
                      <Label className="form-label">Native Language</Label>
                      <select
                        value={language}
                        onChange={onChange}
                        className="form-control"
                      >
                        {(data?.sourceLanguages || []).map((i) => (
                          <option key={i.id} value={i.id}>
                            {i.language}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
                <h5 className="text-md-start text-center">Highlight Style :</h5>
                <Row>
                  <Col lg="4">
                    <div className="mb-3">
                      <Label className="form-label">
                        Background Color
                        <div
                          className="form-check form-switch"
                          style={{
                            display: 'inline-block',
                            padding: 0,
                            position: 'absolute',
                            left: 180,
                          }}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckCheckedDisabled"
                            checked={backgroundColor !== null}
                            onChange={() =>
                              setBackgroundColor(
                                backgroundColor === null ? '#ffffff' : null,
                              )
                            }
                          />
                          {backgroundColor === null && (
                            <label className="form-check-label">No Color</label>
                          )}
                        </div>
                      </Label>
                      <input
                        type="color"
                        className="form-control"
                        style={
                          backgroundColor === null
                            ? {
                                padding: 2,
                                opacity: 0.2,
                                pointerEvents: 'none',
                              }
                            : { padding: 2 }
                        }
                        value={
                          backgroundColor === null ? '#ffffff' : backgroundColor
                        }
                        onChange={(e) => setBackgroundColor(e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="mb-3">
                      <Label className="form-label">Text Color</Label>
                      <input
                        type="color"
                        className="form-control"
                        style={{ padding: 2 }}
                        value={textColor}
                        onChange={(e) => setTextColor(e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col lg="4">
                    <div>
                      <Label className="form-label">Underline Text</Label>
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customSwitch1"
                          checked={underline}
                          onChange={(e) => setUnderline(e.target.checked)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customSwitch1"
                        ></label>
                      </div>
                    </div>
                  </Col>
                  <Col className="pb-4 text-center">
                    <span
                      style={{
                        color: textColor,
                        backgroundColor: backgroundColor,
                        textDecoration: underline ? 'underline' : 'none',
                        cursor: 'pointer',
                      }}
                    >
                      Example Text
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <input
                      type="submit"
                      id="submit"
                      name="send"
                      className="btn btn-primary"
                      value="Save Changes"
                    />
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </section>
  );
};

export default PageSettings;
