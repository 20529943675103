import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import Layout from '../components/Layout/';
import UserLayout from '../components/Layout/user';

// Include Routes
import { useUserContext } from '../context/userProvider';
import PageError from '../pages/Pages/Special/PageError';
import Apps from '../pages/Apps/index';
import { authRoutes, commonRoutes, unAuthRoutes } from './allRoutes';

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};

const AppRoutes = () => {
  const { currentUser, loading } = useUserContext();

  if (loading) return <Loader />;

  return (
    <Router>
      <Suspense fallback={Loader()}>
        <Switch>
          {commonRoutes.map((route, idx) =>
            route.isWithoutLayout ? (
              <Route
                path={route.path}
                exact={route.exact}
                component={route.component}
                key={idx}
              />
            ) : (
              <Route
                path={route.path}
                exact
                render={(props) => (
                  <Layout>
                    <route.component {...props} />
                  </Layout>
                )}
                key={idx}
              />
            ),
          )}
          {authRoutes.map((route, idx) => (
            <Route
              path={route.path}
              exact={route.exact}
              render={(props) =>
                currentUser ? (
                  route.isWithoutLayout ? (
                    <route.component {...props} />
                  ) : (
                    <UserLayout>
                      <route.component {...props} />
                    </UserLayout>
                  )
                ) : (
                  <Redirect to={{ pathname: '/login' }} />
                )
              }
              key={idx}
            />
          ))}
          {unAuthRoutes.map((route, idx) => (
            <Route
              path={route.path}
              exact={route.exact}
              render={(props) =>
                currentUser ? (
                  <Redirect to={{ pathname: '/vocabularies' }} />
                ) : route.isWithoutLayout ? (
                  <route.component {...props} />
                ) : (
                  <Layout>
                    <route.component {...props} />
                  </Layout>
                )
              }
              key={idx}
            />
          ))}
          <Route
            path={""}
            exact={null}
            render={(props) =>
              <Layout>
                <Apps {...props} />
              </Layout>
            }
          />
          <Route
            path={"index"}
            exact={null}
            render={(props) =>
              <Layout>
                <Apps {...props} />
              </Layout>
            }
          />
          <Route path="*" component={PageError} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
