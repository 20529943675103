import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { CheckoutService } from '../../../services';

const style = {
  base: {
    color: '#424242',
    fontSize: '15px',
    lineHeight: '40px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: '#757575',
    },
  },
  invalid: {
    color: '#ff5043',
    ':focus': {
      color: '#303238',
    },
  },
};

const ChangePaymentMethod = ({
  refetchSubscription,
  setChangePaymentMethod,
  setSuccessMessage,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const handleChange = (event) => {
    setErrorMessage(event.error ? event.error.message : null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod: newPaymentMethod } =
      await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

    if (error) {
      setErrorMessage(error.message);
    } else {
      subscribeMutation.mutate({
        paymentMethodId: newPaymentMethod.id,
        cardDisplay: newPaymentMethod.card?.last4
          ? `•••• •••• •••• ${newPaymentMethod.card.last4}`
          : null,
      });
    }
  };

  const subscribeMutation = useMutation(CheckoutService.updatePaymentMethod, {
    onSuccess() {
      refetchSubscription();
      setChangePaymentMethod(false);
      setSuccessMessage('Your payment method has been successfully updated');
    },
    onError: ({ response }) => {
      setErrorMessage(
        response.data && response.data.description
          ? response.data.description
          : response.statusText,
      );
    },
  });

  return (
    <div className="text-center">
      <h3 className="mt-4">Change Payment Method</h3>
      <form onSubmit={handleSubmit}>
        <CardElement
          className="form-control form-stripe"
          options={{ style }}
          onChange={handleChange}
        />
        <button
          type="submit"
          className="btn btn-primary submitBnt"
          disabled={!stripe}
        >
          Change Payment Method
        </button>
        {errorMessage && (
          <p
            style={{
              color: '#ff5043',
              textAlign: 'center',
              marginTop: 10,
              marginBottom: 0,
            }}
          >
            {errorMessage}
          </p>
        )}
        <button
          type="submit"
          className="btn btn-secondary submitBnt"
          style={{ display: 'block', margin: 'auto', marginTop: 10 }}
          onClick={() => setChangePaymentMethod(false)}
        >
          Use Current
        </button>
      </form>
    </div>
  );
};

export default ChangePaymentMethod;
