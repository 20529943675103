import React, { Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import Footer4 from '../../pages/Pages/PageFooterLayouts/Footer4';
import Topbar from './Topbar';
const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};
const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Suspense fallback={Loader()}>
        <Topbar tagline={null} />
        {children}
        <Footer4 />
      </Suspense>
    </React.Fragment>
  );
};
export default withRouter(Layout);
