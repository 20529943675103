import moment from 'moment';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Col, Container, Row } from 'reactstrap';
import { CheckoutService } from '../../../services';
import { currencyFormatter } from '../../../utils/helper';

const PageThankYou = ({}) => {
  const { data, isFetched } = useQuery(
    'getUserSubscription',
    CheckoutService.getUserSubscription,
    {
      retry: false,
    },
  );

  const subscriptionData = data?.subscription || {};
  const amount_in_dollar = currencyFormatter.format(
    subscriptionData.price === 3 ? 2.99 : subscriptionData.price || 0,
  );

  useEffect(() => {
    if (isFetched && !data) {
      window.location.href = '/';
    }
  }, [isFetched]);

  return (
    <React.Fragment>
      <section className="bg-home bg-light d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12}>
              <div className="text-center">
                <div
                  className="icon d-flex align-items-center justify-content-center bg-soft-primary rounded-circle mx-auto"
                  style={{ height: '90px', width: '90px' }}
                >
                  <i className="uil uil-thumbs-up align-middle h1 mb-0"></i>
                </div>
                <h1 className="my-4 fw-bold">Thank you for your purchase!</h1>
                <p className="text-muted para-desc mx-auto">
                  Your subscription will auto-renew on{' '}
                  {moment(subscriptionData.current_period_end).format(
                    'MMMM D, YYYY',
                  )}{' '}
                  for {amount_in_dollar}
                  <br />
                  The total of <b>{amount_in_dollar}</b> has been billed
                </p>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = '/';
                  }}
                  className="btn btn-soft-primary mt-3"
                >
                  Go To Home
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default PageThankYou;
