import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';

// Import Images
import ImageCombined from '../../assets/images/extension/combined.png';
import ImageChrome from '../../assets/images/chrome.png';
import ImageFirefox from '../../assets/images/firefox.png';
import ImageEdge from '../../assets/images/edge.png';

// Modal Video
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';

const Section = () => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <section
        className="bg-half-170 border-bottom agency-wrapper d-table w-100"
        id="home"
      >
        <Container>
          <Row className="align-items-center">
            <Col lg={7} md={7} className="order-1 order-md-2">
              <div className="title-heading mt-4 ms-lg-5">
                <h1 className="heading mb-3">
                  <span className="text-primary">HighVocab</span> - Build and
                  Track Your Vocabulary
                </h1>
                <p className="para-desc text-muted">
                  Keep track of your vocabularies and watch your saved words
                  come alive while you browse the internet with HighVocab's
                  Browser Extension.
                </p>
                <div className="mt-4 pt-2">
                  <a
                    href="https://chrome.google.com/webstore/detail/highvocab-vocabulary-buil/nmalijlbcckplnamalenpdaafacfeemd?hl=en"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary m-1 me-2"
                  >
                    <img
                      src={ImageChrome}
                      style={{ width: 20, height: 20, marginRight: 8 }}
                    />
                    Install on Chrome
                  </a>
                  <a
                    href="https://microsoftedge.microsoft.com/addons/detail/highvocab-vocabulary-bui/dfpolnmicooilcndegandafkmooecmei"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary m-1 me-2"
                  >
                    <img
                      src={ImageEdge}
                      style={{ width: 20, height: 20, marginRight: 10 }}
                    />
                    Install on Edge
                  </a>
                  <a
                    href="https://addons.mozilla.org/en-US/firefox/addon/highvocab-vocabulary-builder/"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary m-1 me-2"
                  >
                    <img
                      src={ImageFirefox}
                      style={{ width: 20, height: 20, marginRight: 8 }}
                    />
                    Install on Firefox
                  </a>
                </div>
                <div className=" pt-2">
                  <Link
                    to="#"
                    onClick={() => setOpen(true)}
                    className="btn btn-icon btn-pills btn-primary m-1 lightbox"
                  >
                    <FeatherIcon icon="video" className="icons" />
                  </Link>{' '}
                  <span className="fw-bold text-uppercase small align-middle">
                    Watch Now
                  </span>
                  <ModalVideo
                    channel="youtube"
                    isOpen={open}
                    videoId="VW8W350kY1w"
                    onClose={() => setOpen(false)}
                  />
                </div>
              </div>
            </Col>
            <Col
              lg={5}
              md={5}
              className="order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0"
            >
              <img
                src={ImageCombined}
                className="img-fluid section-image"
                alt=""
              />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;
