import api from './api';

export const createSubscription = async (params) => {
  const { data } = await api.post('/v1/create_subscription', params);
  return data;
};

export const toggleSubscription = async (cancel_at_period_end) => {
  const { data } = await api.post('/v1/toggle_subscription', {
    cancel_at_period_end,
  });
  return data;
};

export const getUserSubscription = async () => {
  const { data } = await api.get('/v1/user_subscription');
  return data;
};

export const getPlans = async () => {
  const { data } = await api.get('/v1/plans');
  return data;
};

export const getInvoices = async () => {
  const { data } = await api.get('/v1/invoices');
  return data;
};

export const updatePaymentMethod = async ({ paymentMethodId, cardDisplay }) => {
  const { data } = await api.patch('/v1/payment_methods', {
    payment_method_id: paymentMethodId,
    card_display: cardDisplay,
  });
  return data;
};
