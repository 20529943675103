import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { CheckoutService } from '../../../services';

const StripeCard = ({ setError, plan, ...props }) => {
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const [requesting, setRequesting] = useState(false);
  const handleChange = (event) => {
    setError(event.error ? event.error.message : null);
  };

  const style = {
    base: {
      color: '#424242',
      fontSize: '15px',
      lineHeight: '40px',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#757575',
      },
    },
    invalid: {
      color: '#ff5043',
      ':focus': {
        color: '#303238',
      },
    },
  };

  const subscribeMutation = useMutation(CheckoutService.createSubscription, {
    onSuccess(data) {
      setRequesting(false);
      window.setTimeout(() => {
        history.push('/thankyou/password_reset');
      }, 0);
    },
    onError: ({ response }) => {
      setRequesting(false);
      setError(
        response.data && response.data.description
          ? response.data.description
          : response.statusText,
      );
    },
  });

  const submitPayment = async () => {
    setRequesting(true);
    const { token, error } = await stripe.createToken(
      elements.getElement(CardElement),
    );

    if (error) {
      setError(error.message);
      setRequesting(false);
      return null;
    }

    subscribeMutation.mutate({
      token: token.id,
      plan: plan.plan_id,
      duration: plan.duration,
      amount: plan.amount_in_cents,
      display: `•••• •••• •••• ${token.card.last4}`,
    });
  };

  return (
    <div {...props} className="text-center">
      <CardElement
        className="form-control form-stripe"
        options={{ style }}
        onChange={handleChange}
      />
      <button
        className="btn btn-primary submitBnt"
        disabled={requesting}
        onClick={submitPayment}
      >
        Buy Now
      </button>
    </div>
  );
};

export default StripeCard;
