import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Testi = ({}) => {
  return (
    <React.Fragment>
      <section className="section pt-0">
        <Container>
          <Row className="mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
            <Col xs="12" className="text-center">
              <div className="section-title">
                <h4 className="title mb-4">Dive into HighVocab !</h4>
                <p className="text-muted para-desc mx-auto">
                  Embark on a language learning journey like never before! Add{' '}
                  <span className="text-primary fw-bold">HighVocab</span> Browser{' '}
                  extension to your browser today and unlock your true language
                  potential.{' '}
                  <span className="text-primary">Get started for FREE!</span>
                </p>
                <div className="mt-4 pt-2">
                  <a
                    href="https://chrome.google.com/webstore/detail/highvocab-vocabulary-buil/nmalijlbcckplnamalenpdaafacfeemd?hl=en"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                  >
                    Add to chrome <i className="mdi mdi-arrow-right"></i>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Testi;
