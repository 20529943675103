import api from './api';

export const getVocabularies = async (params) => {
  const { data } = await api.get('/v1/vocabularies', { params });
  return data;
};

export const getAllVocabularies = async () => {
  const { data } = await api.get('/v1/vocabularies/all');
  return data;
};

export const exportVocabularies = async () => {
  const { data } = await api.get('/v1/vocabularies/export');
  return data;
};

export const saveVocabulary = async (params) => {
  const { data } = await api.post('/v1/vocabularies', params);
  return data;
};

export const translateVocabulary = async (params) => {
  const { data } = await api.post('/v1/vocabularies/translate', params);
  return data;
};

export const deleteVocabulary = async (id) => {
  const { data } = await api.delete(`/v1/vocabularies/${id}`);
  return data;
};

export const updateVocabulary = async ({ id, ...params }) => {
  const { data } = await api.patch(`/v1/vocabularies/${id}`, params);
  return data;
};
