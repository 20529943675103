import FeatherIcon from 'feather-icons-react';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import { AuthService } from '../../../services';

const ResetPassword = () => {
  const history = useHistory();
  const { token } = useParams();
  const [error, setError] = useState();

  const { mutate } = useMutation(AuthService.reset_password, {
    onSuccess: () => {
      history.push('/success/password_reset');
    },
    onError: ({ response }) => {
      setError(response.data?.error?.message || 'Please Try Again.');
    },
  });

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: '',
      confirm_password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string('Enter your email').required(
        'New password is required',
      ),
      confirm_password: Yup.string()
        .required('Confirm new password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: (values) => {
      mutate({ reset_password_token: token, new_password: values.password });
    },
  });

  return (
    <React.Fragment>
      <div className="back-to-home">
        <Link to="index" className="back-button btn btn-icon btn-primary">
          <i>
            <FeatherIcon icon="arrow-left" className="icons" />
          </i>
        </Link>
      </div>

      <section className="bg-home bg-circle-gradiant d-flex align-items-center">
        <div className="bg-overlay bg-overlay-white"></div>
        <Container>
          {error && (
            <Row className="justify-content-center">
              <Col lg={5} md={8}>
                <Alert color="danger">{error}</Alert>
              </Col>
            </Row>
          )}
          <Row className="justify-content-center">
            <Col lg={5} md={8}>
              <Card className="shadow rounded border-0">
                <CardBody>
                  <h4 className="card-title text-center">Reset Account</h4>

                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    className="login-form mt-4"
                  >
                    <Row>
                      <Col lg={12}>
                        <p className="text-muted">
                          Please enter your new password below. Once you've
                          submitted your new password, you will be able to log
                          in to your account with it.
                        </p>
                        <div className="mb-3">
                          <Label className="form-label">
                            New password <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="lock"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="password"
                            type="password"
                            className="form-control ps-5"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ''}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              {validation.errors.password}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">
                            Confirm new password{' '}
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="lock"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            name="confirm_password"
                            type="password"
                            className="form-control ps-5"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirm_password || ''}
                            invalid={
                              validation.touched.confirm_password &&
                              validation.errors.confirm_password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="d-grid">
                          <Button color="primary">Reset Password</Button>
                        </div>
                      </Col>
                      <div className="mx-auto">
                        <p className="mb-0 mt-3">
                          <small className="text-dark me-2">
                            Remember your password ?
                          </small>{' '}
                          <Link
                            to="/login"
                            className="text-dark fw-bold"
                          >
                            Sign in
                          </Link>
                        </p>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ResetPassword;
