import { TOKEN_KEY } from '../config';
import api from './api';

export const profile = () => {
  const token = window.localStorage.getItem(TOKEN_KEY);
  if (!token) {
    return null;
  }

  return api.get('/v1/users/profile/landing');
};

export const profileUpdate = async (params) => {
  const { data } = await api.patch('/v1/users/profile_update', params);
  return data;
};
