import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useLocation } from 'react-router-dom';
import './Apps.scss';
import './assets/css/materialdesignicons.min.css';
import AppRoutes from './routes/AppRoutes';
import { UserProvider } from './context/userProvider';

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <AppRoutes />
      </UserProvider>
    </QueryClientProvider>
  );
};

export default App;
