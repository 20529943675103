import { loadStripe } from '@stripe/stripe-js';
import moment from 'moment';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Button, Col, Container, Row, UncontrolledAlert } from 'reactstrap';
import { STRIPE_PUBLISHABLE_KEY } from '../../../config';
import { useUserContext } from '../../../context/userProvider';
import { CheckoutService } from '../../../services';
import BillingDetail from './Billing';
import BillingHistory from './BillingHistory';
import Checkout from './Checkout';

const PagePayments = ({ }) => {
  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const { currentUser } = useUserContext();

  const { data = {}, refetch: refetchSubscription } = useQuery(
    'getUserSubscription',
    CheckoutService.getUserSubscription,
  );

  return (
    <React.Fragment>
      <section className="section p-0">
        <Container className="mt-lg-3">
          {successMessage && (
            <Row>
              <Col xs={12}>
                <UncontrolledAlert
                  color="success"
                  style={{ marginTop: 20, marginBottom: 0 }}
                >
                  {successMessage}
                  <Button
                    type="button"
                    className="btn-close"
                    onClick={() => setSuccessMessage(null)}
                  />
                </UncontrolledAlert>
              </Col>
            </Row>
          )}
          {errorMessage && (
            <Row>
              <Col xs={12}>
                <UncontrolledAlert
                  color="danger"
                  style={{ marginTop: 20, marginBottom: 0 }}
                >
                  {errorMessage}
                  <Button
                    type="button"
                    className="btn-close"
                    onClick={() => setErrorMessage(null)}
                  />
                </UncontrolledAlert>
              </Col>
            </Row>
          )}
          {currentUser.subscribed && currentUser.discount_date ? (
            <Row>
              <Col xs={12}>
                <div className="rounded shadow p-4 mt-4 mb-4">
                  Congratulations! You have access to exclusive HighVocab
                  discounts until{' '}
                  {moment(currentUser.discount_date).format('MMMM Do, YYYY')}.
                  Make the most of it!
                </div>
              </Col>
            </Row>
          ) : data.subscription ? (
            <Row>
              <Col xs={12}>
                <div className="rounded shadow p-4 mt-4 mb-4">
                  <BillingDetail
                    setSuccessMessage={setSuccessMessage}
                    setErrorMessage={setErrorMessage}
                    stripePromise={stripePromise}
                    subscriptionData={data.subscription}
                    refetchSubscription={refetchSubscription}
                  />
                  <BillingHistory />
                </div>
              </Col>
            </Row>
          ) : (
            <Checkout stripePromise={stripePromise} />
          )}
        </Container>
      </section>
    </React.Fragment>
  );
};
export default PagePayments;
