// React Basic and Bootstrap
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  UncontrolledAlert
} from 'reactstrap';

//Formik Validation
import FeatherIcon from 'feather-icons-react';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import { AuthService } from '../../../services';

const PageRecoveryPassword = () => {
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    }),
    onSubmit: (values) => {
      forgotPassword.mutate(values);
    },
  });

  const forgotPassword = useMutation(AuthService.forgotPassword, {
    onSuccess: (data) => {
      setSuccess(
        "We've sent you an email with instructions to reset your password. Please check your inbox and follow the link to reset your password.",
      );
      setError('');
      validation.resetForm();
    },
    onError: ({ response }) => {
      setSuccess('');
      setError(
        response.data?.error?.message ||
          'Some thing went wrong. Please try again!',
      );
    },
  });

  return (
    <React.Fragment>
      <div className="back-to-home">
        <Link to="/" className="back-button btn btn-icon btn-primary">
          <i>
            <FeatherIcon icon="arrow-left" className="icons" />
          </i>
        </Link>
      </div>
      <section className="bg-home d-flex align-items-center">
        <Container>
          {success && (
            <Row className="justify-content-center">
              <Col lg={5}>
                <UncontrolledAlert color="success">
                  {success}

                  <Button
                    type="button"
                    className="btn-close"
                    onClick={() => setSuccess(null)}
                  />
                </UncontrolledAlert>
              </Col>
            </Row>
          )}
          {error && (
            <Row className="justify-content-center">
              <Col lg={5}>
                <UncontrolledAlert color="danger">
                  {error}
                  <Button
                    type="button"
                    className="btn-close"
                    onClick={() => setError(null)}
                  />
                </UncontrolledAlert>
              </Col>
            </Row>
          )}
          <Row className="align-items-center justify-content-center">
            <Col lg={5}>
              <Card className="shadow rounded border-0">
                <CardBody>
                  <h4 className="card-title text-center">Recover Account</h4>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    className="login-form mt-4"
                  >
                    <Row>
                      <Col lg={12}>
                        <p className="text-muted">
                          Please enter your email address. You will receive a
                          link to create a new password via email.
                        </p>
                        <div className="mb-3">
                          <Label className="form-label" for="email">
                            Email address <span className="text-danger">*</span>
                          </Label>
                          <div className="form-icon position-relative">
                            <i>
                              <FeatherIcon
                                icon="mail"
                                className="fea icon-sm icons"
                              />
                            </i>
                          </div>
                          <Input
                            type="text"
                            className="form-control ps-5"
                            name="email"
                            id="email"
                            placeholder="Enter Your Email Address"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ''}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="d-grid">
                          <Button color="primary">Send</Button>
                        </div>
                      </Col>
                      <div className="mx-auto">
                        <p className="mb-0 mt-3">
                          <small className="text-dark me-2">
                            Remember your password ?
                          </small>{' '}
                          <Link to="/login" className="text-dark fw-bold">
                            Sign in
                          </Link>
                        </p>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default PageRecoveryPassword;
