import React, { useState } from 'react';
import { PaginationItem, PaginationLink } from 'reactstrap';

const Page = ({ page, setCurrentPage, isActive }) => (
  <PaginationItem className={isActive ? 'active' : ''}>
    <PaginationLink onClick={() => setCurrentPage(page)}>{page}</PaginationLink>
  </PaginationItem>
);

const PagePrevNext = ({ buttonType, setCurrentPage, display }) => {
  if (!display) return;
  return (
    <PaginationItem>
      <PaginationLink onClick={() => setCurrentPage()}>
        {buttonType === 'next' ? 'Next' : 'Prev'}
      </PaginationLink>
    </PaginationItem>
  );
};

const Pagination = (props) => {
  const { cb, totalPage } = props;
  const [currentPage, setCurrentPage] = useState(props.currentPage);

  const handlePage = (pageIndex) => {
    const total = totalPage;
    const current = currentPage;
    const page = pageIndex + 1;

    const limit = 8;
    const preservedDistanceToEdge = 4;
    const distanceToLastPage = Math.abs(total - page);
    const distanceToCurrent = Math.abs(page - current);
    const isEdgePage = page === total || page === 1;
    const isLastPreservedRange =
      total - current < preservedDistanceToEdge &&
      distanceToLastPage < preservedDistanceToEdge;

    let isFirstPreservedRange =
      page <= preservedDistanceToEdge + 1 &&
      current <= preservedDistanceToEdge + 1;

    const configs = {
      page: page,
      isActive: page === currentPage,
    };

    if (current === preservedDistanceToEdge + 1 && total > limit) {
      isFirstPreservedRange = false;
    }

    if (
      total >= limit &&
      current !== page &&
      !isEdgePage &&
      !isFirstPreservedRange &&
      !isLastPreservedRange &&
      distanceToCurrent > 1
    ) {
      return <li className="page truncated">...</li>;
    }

    return (
      <Page
        {...configs}
        {...props}
        key={page}
        setCurrentPage={(page) => {
          if (page) {
            cb(page);
            setCurrentPage(page);
          }
        }}
      />
    );
  };

  // const handleClick = (e) => {
  //   e.preventDefault();
  //   var page = e.target.getAttribute('data-page');
  //   if (page) {
  //     setCurrentPage(parseInt(page, 10));
  //     cb && cb(page);
  //   }
  // };

  var pageArray = Array.from(Array(totalPage).keys());
  var prev = currentPage - 1;
  var next = currentPage + 1;
  return (
    <ul className="pagination justify-content-center mt-3">
      <PagePrevNext
        buttonType="prev"
        display={currentPage !== 1}
        setCurrentPage={() => {
          if (prev) {
            cb(prev);
            setCurrentPage(prev);
          }
        }}
      />
      {pageArray.map((page) => handlePage(page))}
      <PagePrevNext
        buttonType="next"
        display={currentPage !== totalPage}
        setCurrentPage={() => {
          if (next <= totalPage) {
            cb(next);
            setCurrentPage(next);
          }
        }}
      />
    </ul>
  );
};

export default Pagination;
