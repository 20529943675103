import FeatherIcon from 'feather-icons-react';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import {
  Button,
  Col,
  Container,
  FormFeedback,
  Row,
  UncontrolledAlert
} from 'reactstrap';
import * as Yup from 'yup';
import logoIcon from '../../../assets/images/extension/logo-purple-icon.png';
import shape from '../../../assets/images/shape01.png';
import { AuthService } from '../../../services';

const AuthLogin = () => {
  const [error, setError] = useState();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const login = useMutation(AuthService.login, {
    onSuccess: (data) => {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
    onError: ({ response }) => {
      setError(
        response.data?.error?.message ||
          'Some thing went wrong. Please try again!',
      );
    },
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      login.mutate(values);
    },
  });

  return (
    <React.Fragment>
      <div className="back-to-home">
        <Link to="/" className="back-button btn btn-icon btn-primary">
          <i>
            <FeatherIcon icon="arrow-left" className="icons" />
          </i>
        </Link>
      </div>
      <section
        className="bg-home d-flex align-items-center position-relative"
        style={{ backgroundImage: `url(${shape}) center` }}
      >
        <Container>
          {error && (
            <Row className="justify-content-center">
              <Col lg={5} md={8}>
                <UncontrolledAlert color="danger">
                  {error}
                  <Button
                    type="button"
                    className="btn-close"
                    onClick={() => setError(null)}
                  />
                </UncontrolledAlert>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12}>
              <div className="form-signin p-4 bg-white rounded shadow">
                <form onSubmit={formik.handleSubmit}>
                  <Link to="/">
                    <img
                      src={logoIcon}
                      className="avatar avatar-small mb-4 d-block mx-auto"
                      alt=""
                    />
                  </Link>
                  <h5 className="mb-3 text-center">Please sign in</h5>
                  <div className="form-floating mb-2">
                    <input
                      type="email"
                      className={`form-control ${
                        formik.touched.email && formik.errors.email
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="floatingInput"
                      placeholder="name@example.com"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <label htmlFor="floatingInput">Email address</label>
                    {formik.touched.email && formik.errors.email ? (
                      <FormFeedback type="invalid">
                        {formik.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="form-floating mb-3">
                    <input
                      type="password"
                      className={`form-control ${
                        formik.touched.password && formik.errors.password
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="floatingPassword"
                      placeholder="Password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <label htmlFor="floatingPassword">Password</label>
                    {formik.touched.password && formik.errors.password ? (
                      <FormFeedback type="invalid">
                        {formik.errors.password}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="mb-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          Remember me
                        </label>
                      </div>
                    </div>
                    <p className="forgot-pass mb-0">
                      <Link
                        to="/forgot_password"
                        className="text-dark small fw-bold"
                      >
                        Forgot password ?
                      </Link>
                    </p>
                  </div>
                  <button className="btn btn-primary w-100" type="submit">
                    Sign in
                  </button>
                  <div className="col-12 text-center mt-3">
                    <p className="mb-0 mt-3">
                      <small className="text-dark me-2">
                        Don't have an account ?
                      </small>{' '}
                      <Link to="signup" className="text-dark fw-bold">
                        Sign Up
                      </Link>
                    </p>
                  </div>
                  <p className="mb-0 text-muted mt-3 text-center">
                    ©{new Date().getFullYear()} HighVocab.
                  </p>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default AuthLogin;
