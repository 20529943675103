export const TOKEN_KEY = 'HV_PROD_LANDING_TOKEN';
export const BACKEND_URL = 'https://highvocab.com/api';
export const STRIPE_PUBLISHABLE_KEY =
  'pk_live_51MuMhVJtM93s8Cf1kaG7mBVeXC9tWiyUgnQ92CJGy89mmElHAJtlRi6lPSMihpHNjas8YiIZr20KjHehw85vddyT00qQnB7yXv';
export const FREE_VERSION = false;

// export const TOKEN_KEY = 'HV_LOCAL_LANDING_TOKEN';
// export const BACKEND_URL = 'http://localhost:4000/api';
// export const STRIPE_PUBLISHABLE_KEY =
//   'pk_test_51MuMhVJtM93s8Cf1khWqXSCsgHELs5MCfhsxEdty8e52itloMAVIdeEr1WbXnvifW8h6FQ2Rgsl7qAbi2vKqBpwo00YRebWxPE';
