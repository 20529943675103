import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Alert, Col, Container, FormGroup, Row, Table } from 'reactstrap';
import { PhraseService } from '../../../services';
import useDebounce from '../../../utils/useDebounce';

const stripHtml = (html) => {
  let tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

const PagePhrases = () => {
  const [keyword, setKeyword] = useState('');
  const synth = window.speechSynthesis;

  const speak = (text) => {
    const uttr = new SpeechSynthesisUtterance();
    uttr.text = text;
    uttr.rate = 0.8;
    synth.speak(uttr);
  };

  const debouncedKeyword = useDebounce(keyword?.trim(), 500);
  const { data, isLoading } = useQuery(
    ['getPhrases', debouncedKeyword],
    () =>
      PhraseService.getPhrases({
        keyword: debouncedKeyword,
      }),
    {
      retry: false,
    },
  );

  const items = data || [];

  return (
    <section className="section p-0">
      <Container>
        <Row>
          <Col>
            <div className="text-center subcribe-form mb-2 mt-2">
              <form className="m-0" style={{ maxWidth: '300px' }}>
                <FormGroup className="mb-0 mt-3">
                  <input
                    type="text"
                    id="name"
                    className="rounded-pill shadow"
                    placeholder="Search Phrases..."
                    onChange={(e) => {
                      setKeyword(e.target.value);
                    }}
                  />
                </FormGroup>
              </form>
            </div>
            <div className="table-responsive bg-white shadow rounded mb-4 p-3">
              <Table className="mb-0 table-center">
                <thead className="bg-light">
                  <tr>
                    <th
                      scope="col"
                      className="border-bottom"
                      style={{ minWidth: '300px' }}
                    >
                      Phrases
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading && items.length === 0 ? (
                    <div className="loader">Loading ...</div>
                  ) : items.length > 0 ? (
                    items.map((d, i) => (
                      <tr key={d.id}>
                        <td>
                          <div className="d-flex">
                            <i
                              style={{ cursor: 'pointer' }}
                              className="uil uil-volume-up text-muted h5"
                              onClick={(e) => {
                                e.stopPropagation();
                                speak(stripHtml(d.phrase));
                              }}
                            ></i>
                            <div className="flex-1 content ms-3">
                              <p
                                className="mb-0"
                                style={{ whiteSpace: 'pre-line' }}
                                dangerouslySetInnerHTML={{ __html: d.phrase }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <Row className="mt-3">
                      <Col lg={5} md={8}>
                        <Alert color="info">
                          You haven't saved any phrases yet. Dive into the world
                          of expressions and start building your own collection.
                        </Alert>
                      </Col>
                    </Row>
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PagePhrases;
