import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { useUserContext } from '../../context/userProvider';
import NavbarButtons from '../Shared/NavbarButtons';
import LogoPurple from '../../assets/images/extension/logo-purple.png';
import logoLight from '../../assets/images/extension/logo-white.png';

const Topbar = () => {
  const { currentUser } = useUserContext();

  return (
    <header
      id="topnav"
      className="defaultscroll sticky"
      style={{ position: currentUser ? 'absolute' : 'fixed' }}
    >
      <Container>
        <div>
          <Link className="logo" to="/">
            <img
              src={LogoPurple}
              height="24"
              className="logo-light-mode"
              alt=""
            />
            <img
              src={logoLight}
              height="24"
              className="logo-dark-mode"
              alt=""
            />
          </Link>
        </div>
        <NavbarButtons currentUser={currentUser} />
      </Container>
    </header>
  );
};
export default withRouter(Topbar);
