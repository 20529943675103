import React, { useState } from 'react';
import { Col, Container, Nav, NavItem, Row, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

const Pricing = ({}) => {
  const [activeTab, setActiveTab] = useState('monthly');

  return (
    <React.Fragment>
      <Container>
        <Row style={{ marginTop: 100 }}>
          <Col>
            <div className="text-center">
              <Nav
                pills
                justified
                className="nav-pills rounded-pill justify-content-center d-inline-block border py-1 px-2"
              >
                <NavItem className="d-inline-block">
                  <NavLink
                    className={`px-3 rounded-pill monthly ${
                      activeTab === 'monthly' ? 'active' : ''
                    }`}
                    onClick={() => {
                      setActiveTab('monthly');
                    }}
                  >
                    Monthly
                  </NavLink>
                </NavItem>{' '}
                <NavItem className="d-inline-block">
                  <NavLink
                    className={`px-3 rounded-pill yearly ${
                      activeTab === 'yearly' ? 'active' : ''
                    }`}
                    onClick={() => {
                      setActiveTab('yearly');
                    }}
                  >
                    Yearly{' '}
                    <span className="badge rounded-pill bg-success">
                      30% Off{' '}
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Col>
        </Row>
        <Row className="align-items-center mt-3" style={{ marginBottom: 100 }}>
          <Col
            lg={5}
            md={12}
            className="col-lg-5 col-md-12 text-center text-lg-start"
          >
            <div className="section-title mb-4 mb-lg-0 pb-2 pb-lg-0">
              <h4 className="title mb-4">Our Pricing Rates</h4>
              <p className="text-muted para-desc mx-auto mb-0">
                Upgrade your language journey with{' '}
                <span className="text-primary fw-bold">HighVocab</span>. Explore
                our pricing plans and find the perfect fit for your language
                learning needs.
              </p>
              <Link
                to="/login"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary mt-4"
              >
                Buy Now{' '}
                <span className="badge rounded-pill bg-danger ms-2">v1.0</span>
              </Link>
            </div>
          </Col>
          <Col lg={7} md={12}>
            <div className="ms-lg-5">
              <Row className="align-items-center">
                <Col md={6} xs={12} className="mt-4 pt-4 px-md-0">
                  <div className="card pricing pricing-primary starter-plan shadow rounded border-0">
                    <div className="card-body py-5">
                      <h6 className="title name fw-bold text-uppercase mb-4">
                        Starter
                      </h6>
                      <div className="d-flex mb-4">
                        <span className="h4 mb-0 mt-2">$</span>
                        <span className="price h1 mb-0">
                          {activeTab === 'monthly' ? 2.99 : 25}
                        </span>
                        <span className="h4 align-self-end mb-1">
                          {activeTab === 'monthly' ? '/mo' : '/yr'}
                        </span>
                      </div>

                      <ul className="list-unstyled mb-0 ps-0">
                        <li className="h6 text-muted mb-0">
                          <span className="text-primary h5 me-2">
                            <i className="uil uil-check-circle align-middle"></i>
                          </span>
                          Unlimited Word Save
                        </li>
                        <li className="h6 text-muted mb-0">
                          <span className="text-primary h5 me-2">
                            <i className="uil uil-check-circle align-middle"></i>
                          </span>
                          Unlimited Word Highlight
                        </li>
                        <li className="h6 text-muted mb-0">
                          <span className="text-primary h5 me-2">
                            <i className="uil uil-check-circle align-middle"></i>
                          </span>
                          Unlimited Phrase Save
                        </li>
                        <li className="h6 text-muted mb-0">
                          <span className="text-primary h5 me-2">
                            <i className="uil uil-check-circle align-middle"></i>
                          </span>
                          Rephrase Tool
                        </li>
                        <li className="h6 text-muted mb-0">
                          <span className="text-primary h5 me-2">
                            <i className="uil uil-check-circle align-middle"></i>
                          </span>
                          Web Dashboard Access
                        </li>
                        <li className="h6 text-muted mb-0">
                          <span className="text-primary h5 me-2">
                            <i className="uil uil-check-circle align-middle"></i>
                          </span>
                          CSV Export
                        </li>
                      </ul>
                      <Link
                        to="/login"
                        target="_blank"
                        className="btn btn-primary mt-4"
                      >
                        Buy now
                      </Link>
                    </div>
                  </div>
                </Col>

                <div className="col-md-6 col-12 mt-4 pt-2 pt-md-4 px-md-0">
                  <div className="card pricing pricing-primary bg-light shadow rounded border-0">
                    <div className="card-body py-5">
                      <h6 className="title name fw-bold text-uppercase mb-4">
                        Free
                      </h6>
                      <div className="d-flex mb-4">
                        <span className="h4 mb-0 mt-2">$</span>
                        <span className="price h1 mb-0">0</span>
                        <span className="h4 align-self-end mb-1">/mo</span>
                      </div>

                      <ul className="list-unstyled mb-0 ps-0">
                        <li className="h6 text-muted mb-0">
                          <span className="text-primary h5 me-2">
                            <i className="uil uil-check-circle align-middle"></i>
                          </span>
                          20-Word Limit
                        </li>
                        <li className="h6 text-muted mb-0">
                          <span className="text-primary h5 me-2">
                            <i className="uil uil-check-circle align-middle"></i>
                          </span>
                           Highlight first 20 words
                        </li>
                        <li className="h6 text-muted mb-0">
                          <span className="text-primary h5 me-2">
                            <i className="uil uil-check-circle align-middle"></i>
                          </span>
                          30-Phrase Limit
                        </li>
                        <li className="h6 text-muted mb-0">
                          <span className="text-primary h5 me-2">
                            <i className="uil uil-check-circle align-middle"></i>
                          </span>
                          10-Rephrase Usage Limit
                        </li>
                        <li className="h6 text-muted mb-0">
                          <span className="text-danger h5 me-2">
                            <i className="uil uil-check-circle align-middle"></i>
                          </span>
                          No Dashboard Access
                        </li>
                        <li className="h6 text-muted mb-0">
                          <span className="text-danger h5 me-2">
                            <i className="uil uil-times-circle align-middle"></i>
                          </span>
                          No CSV Export
                        </li>
                      </ul>
                      <a
                        href="https://chrome.google.com/webstore/detail/highvocab-vocabulary-buil/nmalijlbcckplnamalenpdaafacfeemd?hl=en"
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-primary mt-4"
                      >
                        Try it now
                      </a>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Pricing;
