import { Elements } from '@stripe/react-stripe-js';
import moment from 'moment';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { CheckoutService } from '../../../services';
import { currencyFormatter } from '../../../utils/helper';
import ChangePaymentMethod from './ChangePaymentMethod';

const BillingDetail = ({
  stripePromise,
  setSuccessMessage,
  setErrorMessage,
  subscriptionData,
  refetchSubscription
}) => {
  const [changePaymentMethod, setChangePaymentMethod] = useState(false);
  const amount_in_dollar = currencyFormatter.format(
    subscriptionData.price === 3 ? 2.99 : subscriptionData.price || 0,
  );

  const toggleRenewMutation = useMutation(CheckoutService.toggleSubscription, {
    onError: ({ response }) => {
      setErrorMessage(
        response.data && response.data.description
          ? response.data.description
          : response.statusText,
      );
    },
  });

  const onToggleAutoRenew = (cancel_at_period_end) =>
    toggleRenewMutation.mutate(cancel_at_period_end, {
      onSuccess() {
        refetchSubscription();
        setSuccessMessage(
          cancel_at_period_end
            ? `Auto-renew has been turned off. Your subscription will expire on ${moment(
                subscriptionData.current_period_end,
                'YYYY-MM-DD',
              ).format('MMMM D, YYYY')}`
            : "You've successfully turned on auto-renew. Your subscription will continue without interruption.",
        );
      },
    });

  const renderStatusMessage = (status) => {
    if (!status) return null;
    switch (status) {
      case 'incomplete':
        return <p style={{ color: 'red' }}>Your payment did not complete. Please update your billing information.</p>;
      case 'past_due':
        return <p style={{ color: 'orange' }}>Your subscription payment is overdue. Please address this as soon as possible.</p>;
      case 'canceled':
        return <p style={{ color: 'grey' }}>Your subscription has been canceled.</p>;
      case 'active':
        return <p style={{ color: 'green' }}>Your subscription is currently active.</p>;
      default:
        return <p>Status: {status}</p>;
    }
  };

  return (
    <>
      <div className="d-flex align-items-center">
        <h5 className="mb-0 text-primary">Billing:</h5>
      </div>
      <div style={{ marginTop: 20 }}>
        {renderStatusMessage(subscriptionData.status)}
      </div>
      <div className="subscription-details">
        <div>
          <strong>
            Premium ({amount_in_dollar}/{subscriptionData.duration})
          </strong>
          <p>
            {["active", "past_due"].includes(subscriptionData.status) && <a
              href="#"
              onClick={(event) => {
                event.preventDefault();
                onToggleAutoRenew(!subscriptionData.cancel_at_period_end);
              }}
            >
              {subscriptionData.cancel_at_period_end
                ? '(Turn on auto renew)'
                : '(Turn off auto renew)'}
            </a>}
          </p>
        </div>
        {
          ["active", "past_due"].includes(subscriptionData.status) && !subscriptionData.cancel_at_period_end && <div>
            <strong>Upcoming invoice</strong>
            <p>
              {amount_in_dollar} -{' '}
              {String(subscriptionData.duration).charAt(0).toUpperCase() +
                String(subscriptionData.duration).slice(1)}{' '}
              Premium Renewal for one{' '}
              {subscriptionData.duration === 'yearly' ? 'year' : 'month'} on{' '}
              {moment(subscriptionData.current_period_end, 'YYYY-MM-DD').format(
                'MMMM D, YYYY',
              )}
            </p>
          </div>
        }
        <div>
          <strong>Payment Method</strong>
          <br />
          {subscriptionData.active_card_display}{' '}
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              setChangePaymentMethod(true);
            }}
          >
            (Change)
          </a>
        </div>
      </div>
      {changePaymentMethod && (
        <Elements stripe={stripePromise}>
          <ChangePaymentMethod
            setChangePaymentMethod={setChangePaymentMethod}
            refetchSubscription={refetchSubscription}
            setSuccessMessage={setSuccessMessage}
          />
        </Elements>
      )}
    </>
  );
};

export default BillingDetail;
