import FeatherIcon from 'feather-icons-react';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import {
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  UncontrolledAlert,
} from 'reactstrap';
import * as Yup from 'yup';
import logoIcon from '../../../assets/images/extension/logo-purple-icon.png';
import shape from '../../../assets/images/shape01.png';
import { AuthService, SourceService } from '../../../services';

const AuthSignup = () => {
  const [error, setError] = useState();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('Email is required'),
    primary_language_id: Yup.string()
      .nullable()
      .required('Please pick your native language'),
    password: Yup.string().required('Password is required'),
    confirm_password: Yup.string()
      .required('Confirm new password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    termsAccepted: Yup.boolean().oneOf(
      [true],
      'You must accept the terms and conditions',
    ),
  });

  const { data } = useQuery('sourceData', SourceService.getSourceData, {
    retry: false,
  });

  const register = useMutation(AuthService.register, {
    onSuccess: (data) => {
      setTimeout(() => {
        window.location.reload();
      }, 500);
    },
    onError: ({ response }) => {
      setError(
        response.data?.error?.message ||
          'Some thing went wrong. Please try again!',
      );
    },
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      primary_language_id: null,
      password: '',
      confirm_password: '',
      termsAccepted: false,
    },
    validationSchema,
    onSubmit: ({ email, password, primary_language_id }) => {
      register.mutate({ email, password, primary_language_id });
    },
  });

  return (
    <React.Fragment>
      <div className="back-to-home">
        <Link to="/" className="back-button btn btn-icon btn-primary">
          <i>
            <FeatherIcon icon="arrow-left" className="icons" />
          </i>
        </Link>
      </div>
      <section
        className="bg-home d-flex align-items-center position-relative"
        style={{ backgroundImage: `url(${shape}) center` }}
      >
        <Container>
          {error && (
            <Row className="justify-content-center">
              <Col lg={5} md={8}>
                <UncontrolledAlert color="danger">
                  {error}
                  <Button
                    type="button"
                    className="btn-close"
                    onClick={() => setError(null)}
                  />
                </UncontrolledAlert>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12}>
              <div className="form-signin p-4 rounded shadow">
                <Form onSubmit={formik.handleSubmit}>
                  <Link to="/">
                    <img
                      src={logoIcon}
                      className="avatar avatar-small mb-4 d-block mx-auto"
                      alt=""
                    />
                  </Link>
                  <h5 className="mb-3 text-center">Register your account</h5>

                  <div className="form-floating mb-2">
                    <Input
                      type="email"
                      className={`form-control ${
                        formik.touched.email && formik.errors.email
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="floatingInput"
                      placeholder="name@example.com"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <Label for="floatingInput">Email</Label>
                    {formik.touched.email && formik.errors.email ? (
                      <FormFeedback type="invalid">
                        {formik.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="form-floating mb-2">
                    <select
                      name="primary_language_id"
                      className={`form-control ${
                        formik.touched.primary_language_id &&
                        formik.errors.primary_language_id
                          ? 'is-invalid'
                          : ''
                      }`}
                      value={formik.values.primary_language_id}
                      onChange={formik.handleChange}
                    >
                      <option value="" disabled selected>
                        Select your primary language
                      </option>
                      {(data?.sourceLanguages || []).map((i) => (
                        <option value={i.id} key={i.id}>
                          {i.language}
                        </option>
                      ))}
                    </select>
                    <Label for="floatingEmail">
                      Select your native language
                    </Label>
                    {formik.touched.primary_language_id &&
                    formik.errors.primary_language_id ? (
                      <FormFeedback type="invalid">
                        {formik.errors.primary_language_id}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="form-floating mb-2">
                    <Input
                      type="password"
                      className={`form-control ${
                        formik.touched.password && formik.errors.password
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="floatingPassword"
                      placeholder="Password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <Label for="floatingEmail">Password</Label>
                    {formik.touched.password && formik.errors.password ? (
                      <FormFeedback type="invalid">
                        {formik.errors.password}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="form-floating mb-3">
                    <Input
                      type="password"
                      className={`form-control ${
                        formik.touched.confirm_password &&
                        formik.errors.confirm_password
                          ? 'is-invalid'
                          : ''
                      }`}
                      id="floatingPassword"
                      placeholder="Confirm Password"
                      name="confirm_password"
                      value={formik.values.confirm_password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <Label for="floatingPassword">Confirm Password</Label>
                    {formik.touched.confirm_password &&
                    formik.errors.confirm_password ? (
                      <FormFeedback type="invalid">
                        {formik.errors.confirm_password}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="form-check mb-3">
                    <Input
                      className="form-check-input"
                      type="checkbox"
                      value={formik.values.termsAccepted}
                      id="termsAccepted"
                      onChange={formik.handleChange}
                    />
                    <Label className="form-check-label" for="termsAccepted">
                      I Accept{' '}
                      <Link
                        to="/terms"
                        target="_blank"
                        className="text-primary"
                      >
                        Terms And Condition
                      </Link>
                    </Label>
                    {formik.touched.confirm_password &&
                    formik.errors.termsAccepted ? (
                      <FormFeedback type="invalid" style={{ display: 'block' }}>
                        {formik.errors.termsAccepted}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <button className="btn btn-primary w-100" type="submit">
                    Register
                  </button>

                  <div className="col-12 text-center mt-3">
                    <p className="mb-0 mt-3">
                      <small className="text-dark me-2">
                        Already have an account ?
                      </small>{' '}
                      <Link to="login" className="text-dark fw-bold">
                        Sign in
                      </Link>
                    </p>
                  </div>

                  <p className="mb-0 text-muted mt-3 text-center">
                    ©{new Date().getFullYear()} HighVocab.
                  </p>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default AuthSignup;
