/* eslint-disable no-undef */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { TOKEN_KEY } from '../config';
import { UserService } from '../services';

export const UserContext = React.createContext({});

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const {
    data: profileRes,
    isSuccess,
    isError,
  } = useQuery('userProfile', UserService.profile, {
    retry: false,
  });

  useEffect(() => {
    if (isSuccess) {
      setIsLoading(false);
      setCurrentUser(profileRes?.data);
    } else if (isError) {
      setIsLoading(false);
    }
  }, [isSuccess, isError, profileRes]);

  return (
    <UserContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        logout: () => {
          window.localStorage.removeItem(TOKEN_KEY);
          if (axios.defaults.headers.common['Authorization']) {
            delete axios.defaults.headers.common['Authorization'];
          }
          setCurrentUser(null);

          const history = useHistory();
          history.push('/login');
        },
        loading: isLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const withUserContext = (Component) =>
  function MyComponent(props) {
    return (
      <UserContext.Consumer>
        {(value) => {
          return <Component {...props} />;
        }}
      </UserContext.Consumer>
    );
  };

export const useUserContext = () => React.useContext(UserContext);
