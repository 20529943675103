import api from './api';

export const getPhrases = async (params) => {
  const { data } = await api.get('/v1/phrases', { params });
  return data;
};

export const savePhrase = async (params) => {
  const { data } = await api.post('/v1/phrases', params);
  return data;
};

export const deletePhrase = async (id) => {
  const { data } = await api.delete(`/v1/phrases/${id}`);
  return data;
};

