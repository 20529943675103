import { TOKEN_KEY } from '../config';
import api from './api';

const saveAccessToken = (accessToken) => {
  window.localStorage.setItem(TOKEN_KEY, JSON.stringify({ accessToken }));
  api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export const login = async (params) => {
  const { data } = await api.post('/v1/auth/login', params);
  saveAccessToken(data.accessToken);
  return data;
};

export const register = async (params) => {
  const { data } = await api.post('/v1/auth/registration', params);
  saveAccessToken(data.accessToken);
  return data;
};

export const forgotPassword = async (params) => {
  const { data } = await api.post('/v1/passwords/forgot', params);
  return data;
};

export const reset_password = async (params) => {
  const { data } = await api.post('/v1/passwords/reset', params);
  return data;
};
